import React from 'react'
import { Routes, Route } from 'react-router'
import { LANGUAGES } from '../_i18n/languages';
import loadable from '@loadable/component'
import {
    useLocation,
    useParams
} from "react-router-dom";

const components = {
    'About': loadable(() => import('../about/About')),
    'Terms': loadable(() => import('../terms/Terms')),
    'Privacy': loadable(() => import('../privacy/Privacy')),
    'GMaps': loadable(() => import('../embed/GMaps')),
    'OSMap': loadable(() => import('../embed/OSMap')),
    'GMapsQrCode': loadable(() => import('../qrcode/GMapsQrCode'))
}
const NotFound = loadable(() => import('../notFound/NotFound'))


const MultiLanguageRoute = ({ host, Component, location, custom }) => {
    const params = useParams();
    //console.log('params->'+JSON.stringify(params));
    //console.log(location)

    let hasLang = params.lang
    hasLang = hasLang ? hasLang : 'en'
    // let pathParam1 = location.pathname.split('/')[1]
    // let pathParam2 = props.location.pathname.split('/')[2]
    //let pathParam3 = location.pathname.split('/')[3]
    const is404Page = location.pathname.split('/')[3]
    //const is404Page = (pathParam3 && pathParam1 !== 'countdown-timer') ? true : pathParam3 && pathParam3.indexOf('timer') === -1
    //const isBasePathWithoutLang = props.location.pathname === "/"
    const currentLanguage = LANGUAGES[hasLang]

    //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
    //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
    if (!currentLanguage || is404Page) return <NotFound location={location} staticContext={location.staticContext} />

    return <Component host={host} location={location} custom={custom} />
}

const Router = (props) => {
    const location = useLocation();
    location.staticContext = props.staticContext;
    //console.log(location); 

    const seoLinks = [
        {
            'domain': 'embedmaps.co',
            'path': '/about',
            'component': 'About',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },
        {
            'domain': 'embedmaps.co',
            'path': '/terms',
            'component': 'Terms',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },
        {
            'domain': 'embedmaps.co',
            'path': '/privacy',
            'component': 'Privacy',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },
        {
            'domain': 'embedmaps.co',
            'path': '/:lang?',
            'component': 'GMaps',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },
        {
            'domain': 'embedmaps.co',
            'path': '/create-google-map/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.create-google-map.seo.title',
            'description': 'embedGMaps.create-google-map.seo.description',
            'h1': 'embedGMaps.create-google-map.h1',
            'desc1': 'embedGMaps.create-google-map.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/embed-google-maps/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.embed-google-maps.seo.title',
            'description': 'embedGMaps.embed-google-maps.seo.description',
            'h1': 'embedGMaps.embed-google-maps.h1',
            'desc1': 'embedGMaps.embed-google-maps.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/google-maps/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.google-maps.seo.title',
            'description': 'embedGMaps.google-maps.seo.description',
            'h1': 'embedGMaps.google-maps.h1',
            'desc1': 'embedGMaps.google-maps.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/how-to-embed-google-maps/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.how-to-embed-google-maps.seo.title',
            'description': 'embedGMaps.how-to-embed-google-maps.seo.description',
            'h1': 'embedGMaps.how-to-embed-google-maps.h1',
            'desc1': 'embedGMaps.how-to-embed-google-maps.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/google-maps-generator/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.google-maps-generator.seo.title',
            'description': 'embedGMaps.google-maps-generator.seo.description',
            'h1': 'embedGMaps.google-maps-generator.h1',
            'desc1': 'embedGMaps.google-maps-generator.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/google-maps-directions/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.google-maps-directions.seo.title',
            'description': 'embedGMaps.google-maps-directions.seo.description',
            'h1': 'embedGMaps.google-maps-directions.h1',
            'desc1': 'embedGMaps.google-maps-directions.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/my-maps-google/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.my-maps-google.seo.title',
            'description': 'embedGMaps.my-maps-google.seo.description',
            'h1': 'embedGMaps.my-maps-google.h1',
            'desc1': 'embedGMaps.my-maps-google.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/how-to-drop-pin-on-google-maps/:lang?',
            'component': 'GMaps',
            'title': 'embedGMaps.how-to-drop-pin-on-google-maps.seo.title',
            'description': 'embedGMaps.how-to-drop-pin-on-google-maps.seo.description',
            'h1': 'embedGMaps.how-to-drop-pin-on-google-maps.h1',
            'desc1': 'embedGMaps.how-to-drop-pin-on-google-maps.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/google-maps-qr-code/:lang?',
            'component': 'GMapsQrCode',
            'title': 'qrcodeGMaps.google-maps-qrcode-generator.seo.title',
            'description': 'qrcodeGMaps.google-maps-qrcode-generator.seo.description',
            'h1': 'qrcodeGMaps.google-maps-qrcode-generator.h1',
            'desc1': 'qrcodeGMaps.google-maps-qrcode-generator.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/qr-code-generator-for-google-maps/:lang?',
            'component': 'GMapsQrCode',
            'title': 'qrcodeGMaps.qr-code-generator-for-google-maps.seo.title',
            'description': 'qrcodeGMaps.qr-code-generator-for-google-maps.seo.description',
            'h1': 'qrcodeGMaps.qr-code-generator-for-google-maps.h1',
            'desc1': 'qrcodeGMaps.qr-code-generator-for-google-maps.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/location-qr/:lang?',
            'component': 'GMapsQrCode',
            'title': 'qrcodeGMaps.location-qr.seo.title',
            'description': 'qrcodeGMaps.location-qr.seo.description',
            'h1': 'qrcodeGMaps.location-qr.h1',
            'desc1': 'qrcodeGMaps.location-qr.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/create-qr-code-for-location/:lang?',
            'component': 'GMapsQrCode',
            'title': 'qrcodeGMaps.create-qr-code-for-location.seo.title',
            'description': 'qrcodeGMaps.create-qr-code-for-location.seo.description',
            'h1': 'qrcodeGMaps.create-qr-code-for-location.h1',
            'desc1': 'qrcodeGMaps.create-qr-code-for-location.desc.1'
        },
        {
            'domain': 'embedmaps.co',
            'path': '/openstreetmap/:lang?',
            'component': 'OSMap',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },


        {
            'domain': 'mapembed.net',
            'path': '/about',
            'component': 'About',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },
        {
            'domain': 'mapembed.net',
            'path': '/terms',
            'component': 'Terms',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },
        {
            'domain': 'mapembed.net',
            'path': '/privacy',
            'component': 'Privacy',
            'title': '',
            'description': '',
            'h1': '',
            'desc1': ''
        },
        {
            'domain': 'mapembed.net',
            'path': '/:lang?',
            'component': 'GMaps',
            'title': 'mapembed.embedGMaps.seo.title',
            'description': 'mapembed.embedGMaps.seo.description',
            'h1': 'mapembed.embedGMaps.h1',
            'desc1': 'mapembed.embedGMaps.desc.1'
        },
        {
            'domain': 'mapembed.net',
            'path': '/openstreetmap/:lang?',
            'component': 'OSMap',
            'title': 'mapembed.embedOSM.seo.title',
            'description': 'mapembed.embedOSM.seo.description',
            'h1': 'mapembed.embedOSM.h1',
            'desc1': 'mapembed.embedOSM.desc.1'    
        },
        {
            'domain': 'mapembed.net',
            'path': '/google-maps-qr-code/:lang?',
            'component': 'GMapsQrCode',
            'title': 'mapembed.qrcodeGMaps.seo.title',
            'description': 'mapembed.qrcodeGMaps.seo.description',
            'h1': 'mapembed.qrcodeGMaps.h1',
            'desc1': 'mapembed.qrcodeGMaps.desc.1'
        },

    ];

    return (
        <React.Fragment>
            <Routes>
                {seoLinks.map((seo, idx) => {
                    let host = ((typeof window !== 'undefined') ? window.location.hostname : props.host).replace('www.', '');
                    if (host === 'embedmaps.co' && host === seo.domain) {
                        return <Route key={idx} path={seo.path} element={<MultiLanguageRoute {...props} Component={components[seo.component]} location={location} custom={seo} />} />
                    }

                    if (host === 'mapembed.net' && host === seo.domain) {
                        return <Route key={idx} path={seo.path} element={<MultiLanguageRoute {...props} Component={components[seo.component]} location={location} custom={seo} />} />
                    }

                    return ''
                })}
                <Route path='*' element={<NotFound {...props} />} />
            </Routes>
        </React.Fragment>
    )
}

export default Router