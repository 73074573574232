import React from 'react'
import { FormattedMessage } from 'react-intl';

const footer = (props) => {
    let host = ((typeof window !== 'undefined') ? window.location.hostname : props.host).replace('www.', '');
    return (
        <footer className="footer mt-auto py-3 bg-light">
            <div className="container">
                <div className="left">
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="/terms" rel="nofollow"><FormattedMessage id="footer.terms" /></a></li>
                        <li className="list-inline-item"><a href="/privacy" rel="nofollow"><FormattedMessage id="footer.privacy" /></a></li>
                        <li className="list-inline-item"><a href="/about" rel="nofollow"><FormattedMessage id="footer.about" /></a></li>
                    </ul>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="text-muted">{host} © {new Date().getFullYear()}</span>
                </div>

            </div>
        </footer>
    )
}

export default footer