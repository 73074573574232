import React from 'react'

const header = (props) => {
  const hostFull = ((typeof window !== 'undefined') ? window.location.hostname : props.host);
  const host = hostFull.replace('www.', '');
  const siteName = {
    'embedmaps.co': 'Embed Maps',
    'mapembed.net': 'Map Embed',
  }
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand logo-text" href="/">
            <img src="/embed-map-with-pin.png" srcSet="/embed-map-with-pin-32.png 2000w" alt="embed map with pin on website" className="logo" />{siteName[host]}</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <ul className="navbar-nav mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link" href="/" title="Embed Google Maps">Embed Google Maps</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/google-maps-qr-code" title="Google Maps QR Code Generator">Google Maps QR Code Generator</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/openstreetmap" title="Embed OpenStreetMap">Embed OpenStreetMap</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/blog" >Blog</a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header >
  )
}

export default header