import React from 'react'
import Helmet from 'react-helmet';

const seo = (props) => {
  const hostFull = ((typeof window !== 'undefined') ? window.location.hostname : props.host);
  const host = hostFull.replace('www.', '');
  const analyticsIds = {
    'embedmaps.co': 'G-D9LYRBCMP6',
    'mapembed.net': 'G-J4F1G87KVN'
  }
  return (
    <Helmet>
      <meta property="og:image" content={`https://${hostFull}/embed-map-with-pin.png`} />
      <meta name="author" content={`${host.split(".")[0]}`} />
      <link rel="manifest" href={`/manifest-${host.split(".")[0]}.json`} />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${analyticsIds[host]}`}></script>
      <script>
        {`
        var host = window.location.hostname;
        if (host != "localhost") {
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${analyticsIds[host]}');
        }
        `}
      </script>

      {/* <!-- Google Tag Manager --> */}
      {(host === 'embedmaps.co') ? <script>{`(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-N6LJWBBW');`}</script> : ''}

      {(host === 'embedmaps.co') ? <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N6LJWBBW" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>`}</noscript> : ''}
      {/* <!-- Google Tag Manager --> */}
    </Helmet>
  )
}

export default seo